import { ApiClientV2Default } from 'utils/api-client/api-client.utils'

import { UserStore } from 'shared/stores/user.store'
import { ManagerStore } from 'shared/stores/manager.store'
import { PublisherStore } from 'shared/stores/publisher.store'
import { EloPublisherStore } from 'shared/stores/eloPublisher.store'
import { TeamMemberStore } from 'shared/stores/teamMember.store'
import { PayerStore } from 'shared/stores/payer.store'
import { SalesTeamMemberStore } from 'shared/stores/salesTeamMember.store'
import { OptionsStore } from 'shared/stores/options.store'
import { CurrenciesStore } from 'shared/stores/currencies.store'
import { VatUserRegistrationsStore } from 'shared/stores/vatUserRegistrations.store'

import { ViewLogsStore } from 'shared/stores/viewLogs.store'
import { UserProfilesStore } from 'shared/stores/userProfiles.store'
import { BankAccountsStore } from 'shared/stores/bankAccounts.store'
import { PayoutSettingsStore } from 'shared/stores/payoutSettings.store'
import { CommunitiesStore } from 'shared/stores/communities.store'
import { CommunityMemberStore } from 'shared/stores/communityMember.store'
import { CommunityMembersStore } from 'shared/stores/communityMembers.store'
import { CommunityPostsStore } from 'shared/stores/communityPosts.store'
import { CommunityLabelsStore } from 'shared/stores/communityLabels.store'
import { CommunitiesFeedPostsStore } from 'shared/stores/communitiesFeedPosts.store'
import { CommunitiesMembersStore } from 'shared/stores/communitiesMembers.store'
import { AudioPlayersStore } from 'shared/stores/audioPlayers.store'
import { AffiliateProgramsStore } from 'shared/stores/affiliatePrograms.store'

import { SellerStore } from './seller.store'
import { CoversStore } from './covers.store'
import { LessonsStore } from './lessons.store'
import { CommentsStore } from './comments.store'
import { QuizzesStore } from './quizzes.store'
import { TransfersStore } from './transfers.store'
import { OrdersStore } from './orders.store'
import { OrderVatChangeStore } from './orderVatChange.store'
import { CourseSessionsStore } from './courseSessions.store'
import { CourseViewsStore } from './courseViews.store'
import { SellersStore } from './sellers.store'
import { SellablesStore } from './sellables.store'
import { SellableItemsStore } from './sellableItems.store'
import { NotificationsStore } from './notifications.store'
import { MembershipThemesStore } from './membershipThemes.store'
import { ProductsStore } from './products.store'
import { ContentPageStore } from './contentPage.store'
import { PricingPlansStore } from './pricingPlans.store'
import { SellerSettingsStore } from '../../cabinet/stores/sellerSettings.store'
import { ProductGroupsStore } from './productGroups.store'
import { MembershipSessionsStore } from './membershipSessions.store'
import { FontsStore as SellerFontsStore } from './sellerFonts.store'
import { CourseThemesStore } from './courseThemes.store'
import { CsvLogsStore } from './csvLogs.store'
import { ProductSessionStore } from './productSession.store'
import { InvoicesStore } from './invoices.store'
import { CreditMemosStore } from './creditMemos.store'
import { CountriesStore } from './countries.store'

import { LessonStatusesStore } from './lessonStatuses.store'

export class PayerRootStore {
  apiClient: ApiClientV2Default

  userStore: UserStore
  managerStore: ManagerStore
  sellerStore: SellerStore
  publisherStore: PublisherStore
  eloPublisherStore: EloPublisherStore
  teamMemberStore: TeamMemberStore
  payerStore: PayerStore
  salesTeamMemberStore: SalesTeamMemberStore
  optionsStore: OptionsStore
  currenciesStore: CurrenciesStore
  countriesStore: CountriesStore
  vatUserRegistrationsStore: VatUserRegistrationsStore
  viewLogsStore: ViewLogsStore
  userProfilesStore: UserProfilesStore
  bankAccountsStore: BankAccountsStore
  payoutSettingsStore: PayoutSettingsStore
  communitiesStore: CommunitiesStore
  communityMemberStore: CommunityMemberStore
  communityMembersStore: CommunityMembersStore
  communityPostsStore: CommunityPostsStore
  communityLabelsStore: CommunityLabelsStore
  communitiesFeedPostsStore: CommunitiesFeedPostsStore
  communitiesMembersStore: CommunitiesMembersStore
  audioPlayersStore: AudioPlayersStore

  coversStore: CoversStore
  lessonsStore: LessonsStore
  commentsStore: CommentsStore
  quizzesStore: QuizzesStore
  transfersStore: TransfersStore
  ordersStore: OrdersStore
  orderVatChangeStore: OrderVatChangeStore
  courseSessionsStore: CourseSessionsStore
  courseViewsStore: CourseViewsStore
  lessonStatusesStore: LessonStatusesStore
  sellersStore: SellersStore
  sellablesStore: SellablesStore
  sellableItemsStore: SellableItemsStore
  notificationsStore: NotificationsStore
  membershipThemesStore: MembershipThemesStore
  productsStore: ProductsStore
  affiliateProgramsStore: AffiliateProgramsStore
  pricingPlansStore: PricingPlansStore
  sellerSettingsStore: SellerSettingsStore
  productGroupsStore: ProductGroupsStore
  membershipSessionsStore: MembershipSessionsStore
  sellerFontsStore: SellerFontsStore
  courseThemesStore: CourseThemesStore
  csvLogsStore: CsvLogsStore
  productSessionStore: ProductSessionStore
  invoicesStore: InvoicesStore
  creditMemosStore: CreditMemosStore

  contentPageStore: ContentPageStore

  pageBuilderStore: any
  lemonwayLegitimationStore: any
  mangopayLegitimationStore: any
  inkassoLegitimationStore: any
  elopageConnectStore: any
  resellerRequestStore: any
  paymentSettingStore: any
  countriesV2Store: any
  profileDeletionStore: any
  powerSellerDocumentsStore: any

  constructor() {
    this.apiClient = new ApiClientV2Default()

    this.userStore = new UserStore()
    this.managerStore = new ManagerStore()
    this.publisherStore = new PublisherStore()
    this.eloPublisherStore = new EloPublisherStore()
    this.teamMemberStore = new TeamMemberStore()
    this.payerStore = new PayerStore()
    this.salesTeamMemberStore = new SalesTeamMemberStore()

    this.optionsStore = new OptionsStore()
    this.currenciesStore = new CurrenciesStore()
    this.vatUserRegistrationsStore = new VatUserRegistrationsStore()

    this.userProfilesStore = new UserProfilesStore()
    this.bankAccountsStore = new BankAccountsStore()
    this.payoutSettingsStore = new PayoutSettingsStore()
    this.communitiesStore = new CommunitiesStore()
    this.communityMemberStore = new CommunityMemberStore()
    this.communityMembersStore = new CommunityMembersStore()
    this.communityPostsStore = new CommunityPostsStore()
    this.communityLabelsStore = new CommunityLabelsStore()
    this.communitiesFeedPostsStore = new CommunitiesFeedPostsStore()
    this.communitiesMembersStore = new CommunitiesMembersStore()
    this.audioPlayersStore = new AudioPlayersStore()

    this.coversStore = new CoversStore()
    this.lessonsStore = new LessonsStore()
    this.commentsStore = new CommentsStore()
    this.quizzesStore = new QuizzesStore()
    this.transfersStore = new TransfersStore()
    this.ordersStore = new OrdersStore()
    this.orderVatChangeStore = new OrderVatChangeStore()
    this.courseSessionsStore = new CourseSessionsStore()
    this.lessonStatusesStore = new LessonStatusesStore()
    this.sellersStore = new SellersStore()
    this.sellablesStore = new SellablesStore()
    this.sellableItemsStore = new SellableItemsStore()
    this.notificationsStore = new NotificationsStore()
    this.membershipThemesStore = new MembershipThemesStore()
    this.productsStore = new ProductsStore()
    this.affiliateProgramsStore = new AffiliateProgramsStore()
    this.pricingPlansStore = new PricingPlansStore()
    this.sellerSettingsStore = new SellerSettingsStore()
    this.productGroupsStore = new ProductGroupsStore()
    this.membershipSessionsStore = new MembershipSessionsStore()
    this.sellerFontsStore = new SellerFontsStore()
    this.courseThemesStore = new CourseThemesStore()
    this.csvLogsStore = new CsvLogsStore()
    this.invoicesStore = new InvoicesStore()
    this.creditMemosStore = new CreditMemosStore()

    this.countriesStore = new CountriesStore(this)
    this.sellerStore = new SellerStore(this)
    this.viewLogsStore = new ViewLogsStore()
    this.productSessionStore = new ProductSessionStore(this)
    this.courseViewsStore = new CourseViewsStore(this)
    this.contentPageStore = new ContentPageStore(this)

    this.pageBuilderStore = {}
    this.lemonwayLegitimationStore = {}
    this.mangopayLegitimationStore = {}
    this.inkassoLegitimationStore = {}
    this.elopageConnectStore = {}
    this.resellerRequestStore = {}
    this.paymentSettingStore = {}
    this.countriesV2Store = {}
    this.profileDeletionStore = {}
    this.powerSellerDocumentsStore = {}
  }
}
